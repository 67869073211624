import { Container } from ".//Container";

export function Footer() {
  return (
    <footer className="border-t border-gray-200">
      <Container>
        <div className="flex flex-col items-center border-t border-gray-200 pt-8 pb-8 md:flex-row-reverse md:justify-between md:pt-6">
          <p className="mt-6 text-sm text-gray-500 md:mt-0">
            &copy; Copyright {new Date().getFullYear()}. Alle rettigheder
            forbeholdes.
          </p>
        </div>
      </Container>
    </footer>
  );
}
