import clsx from "clsx";

import { Button } from "./Button";
import { Container } from "./Container";

const plans = [
  {
    name: "Graviditetsforløb med Min Mave",
    featured: false,
    price: { Monthly: "29 kr. for hele forløbet" },
    description:
      "Rabatten tilføjes ved checkout. Vi trækker aldrig noget fra dit kort.",
    button: {
      label: "Kom i gang",
      href: "/get-started",
    },
    features: [
      "Over 70 videoer, podcasts og PDFs der frigives løbende uge for uge",
      "Adgang til hele vores Goodie Box med tilbud fra partnere landet over og online.",
    ],
    logomarkClassName: "fill-gray-300",
  },
];

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        fill="currentColor"
      />
      <circle
        cx="12"
        cy="12"
        r="8.25"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Plan({
  name,
  price,
  description,
  button,
  features,
  featured = false,
}) {
  return (
    <section
      className={clsx(
        "mx-auto flex flex-col overflow-hidden rounded-3xl max-w-md p-6 shadow-lg shadow-gray-900/5",
        featured ? "order-first bg-gray-900 lg:order-none" : "bg-white"
      )}
    >
      <h3
        className={clsx(
          "flex items-center text-sm font-semibold",
          featured ? "text-white" : "text-gray-900"
        )}
      >
        {name}
      </h3>
      <p
        className={clsx(
          "relative mt-5 flex text-3xl tracking-tight",
          featured ? "text-white" : "text-gray-900"
        )}
      >
        {price.Monthly === price.Annually ? (
          price.Monthly
        ) : (
          <>
            <span className={clsx("transition duration-300")}>
              {price.Monthly}
            </span>
          </>
        )}
      </p>
      <p
        className={clsx(
          "mt-3 text-sm",
          featured ? "text-gray-300" : "text-gray-700"
        )}
      >
        {description}
      </p>
      <div className="order-last mt-6">
        <ul
          role="list"
          className={clsx(
            "-my-2 divide-y text-sm",
            featured
              ? "divide-gray-800 text-gray-300"
              : "divide-gray-200 text-gray-700"
          )}
        >
          {features.map((feature) => (
            <li key={feature} className="flex py-2">
              <CheckIcon
                className={clsx(
                  "h-6 w-6 flex-none",
                  featured ? "text-white" : "text-mm-purple"
                )}
              />
              <span className="ml-4">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <Button
        href={button.href}
        color={featured ? "cyan" : "gray"}
        className="mt-6"
        aria-label={`Get started with the ${name} plan for ${price}`}
      >
        {button.label}
      </Button>
    </section>
  );
}

export function Pricing() {
  return (
    <section
      id="pricing"
      aria-labelledby="pricing-title"
      className="border-t border-gray-200 bg-gray-100 py-16 sm:py-24"
    >
      <Container>
        <div className="mx-auto max-w-2xl">
          <Plan {...plans[0]} />
        </div>
      </Container>
    </section>
  );
}
