import { CallToAction } from "../client/marketing/CallToAction";
import { Faqs } from "../client/marketing/Faqs";
import { Footer } from "../client/marketing/Footer";
import { Header } from "../client/marketing/Header";
import { Hero } from "../client/marketing/Hero";
import { Pricing } from "../client/marketing/Pricing";
import { PrimaryFeatures } from "../client/marketing/PrimaryFeatures";
import { Reviews } from "../client/marketing/Reviews";
import { SecondaryFeatures } from "../client/marketing/SecondaryFeatures";

function Homepage() {
  return (
    <>
      <Header />
      <main>
        <Hero />
        <PrimaryFeatures />
        <SecondaryFeatures />
        <CallToAction />

        <Pricing />
        <Faqs />
      </main>
      <Footer />
    </>
  );
}

export default Homepage;
