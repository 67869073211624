import { Container } from "./Container";

const features = [
  {
    name: "Træning under graviditeten",
    description:
      "Vores GynObs og yogainstruktør hjælper dig med at holde din gravide krop stærk, smighed og sund.",
  },
  {
    name: "Klar til baby",
    description:
      "Sundhedsplejerskerne forbereder dig på amning og babys søvn, mens vores juridiske rådgiver forklarer hvad du skal tage højde for ved en familieforøgelse.",
  },
  {
    name: "Vi skal være forældre",
    description:
      "En sexolog og parterapeut snakker med os om parforholdet, og de forandringer der kan komme når 2 bliver til 3.",
  },
  {
    name: "Min krop forandrer sig",
    description:
      "Vores hold af jordemødre tager dig i hånden igennem alle de forandringer og udfordringer din krop står overfor, fra uge 6 til fødslen.",
  },
  {
    name: "Bliv tanket op",
    description:
      "Find et øjebliks ro for dig og din gravide krop med vores meditationer og en guide til gravidmassage udført af din partner.",
  },
  {
    name: "Fødselsforberedelse",
    description:
      "Du får fødselsforberedelse som passer både til dig og din partner i dit 3. trimester.",
  },
];

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for building a portfolio"
      className="py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-medium tracking-tight text-gray-900">
            Bliv klogere på alle aspekter af graviditeten
          </h2>
          <p className="mt-2 text-lg text-gray-600">
            Vores graviditetsforløb dækker alle emner, der kan være relevante
            for dig i din graviditet. Fra kost og motion, fødselsforberedelse
            til alt det praktiske. Bliv klædt godt på med vores fagpersoner.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3"
        >
          {features.map((feature) => (
            <li
              key={feature.name}
              className="rounded-2xl border border-mm-light-rose p-8"
            >
              <h3 className="font-semibold text-gray-900">{feature.name}</h3>
              <p className="mt-2 text-gray-700">{feature.description}</p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
