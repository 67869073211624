import { forwardRef } from "react";
import clsx from "clsx";

export function AppScreen({ children, className, ...props }) {
  return (
    <div className={clsx("flex flex-col", className)} {...props}>
      <div className="flex justify-between px-4 pt-4">
        <img src="/images/mm-logo.png" className="h-6 flex-none" />
      </div>
      {children}
    </div>
  );
}

AppScreen.Header = forwardRef(function AppScreenHeader({ children }, ref) {
  return (
    <div ref={ref} className="mt-6 px-4 text-white">
      {children}
    </div>
  );
});

AppScreen.Title = forwardRef(function AppScreenTitle({ children }, ref) {
  return (
    <div ref={ref} className="text-xl text-mm-purple font-bold">
      {children}
    </div>
  );
});

AppScreen.Subtitle = forwardRef(function AppScreenSubtitle({ children }, ref) {
  return (
    <div ref={ref} className="text-sm text-black font-medium mt-2">
      {children}
    </div>
  );
});

AppScreen.Body = forwardRef(function AppScreenBody(
  { children, className },
  ref
) {
  return (
    <div ref={ref} className={clsx("mt-6 flex-auto bg-white", className)}>
      {children}
    </div>
  );
});
