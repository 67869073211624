import Link from "next/link";

import { Container } from ".//Container";

const faqs = [
  [
    {
      question: "Hvorfor kan jeg ikke se alt indhold?",
      answer:
        "Vi åbner op for nyt indhold til dig hver gang du har gravid uge skift. Dvs hvis du går fra graviduge 6 til graviduge 7 om torsdagen, så kan du forvente nyt indhold på din konto samme dag.",
    },
    {
      question: "Min terminsdato er blevet rykket?",
      answer:
        "Du ændrer din terminsdato i menupunktet “Min profil”. Din terminsdato kan flyttes 4 uger fra den oprindelige. Har du brug for at rykke den yderligere, skal du kontakte vores support.",
    },
    {
      question: "Er jeg bundet til noget?",
      answer:
        "Nej! Du kan altid opsige dit abonnement ved at gå til siden Min Profil og trykke Administrer abonnement.",
    },
  ],
  [
    {
      question: "Hvem er jeres fagfolk?",
      answer:
        "Vores fagfolk er alle uddannede og med lang erfaring fra de felter, de rådgiver omkring. Det gælder blandt andet jordemødre, sundhedsplejerske, GynObs, yogainstruktører, diætister mv.",
    },
    {
      question: "Hvem er I?",
      answer:
        "Min-Mave.dk startede for 20 år siden og er siden da blevet et af Danmarks største online mødesteder for kvinder, der søger fertilitet, er gravide eller forældre til småbørn.",
    },
    {
      question: "Jeg har feedback",
      answer:
        "Vi elsker feedback; både god og dårlig. Du kan altid skrive på support@min-mave.dk eller via chatten på siden her. Vi glæder os til at høre fra dig!",
    },
  ],
];

export function Faqs() {
  return (
    <section
      id="faqs"
      aria-labelledby="faqs-title"
      className="border-t border-gray-200 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faqs-title"
            className="text-3xl font-medium tracking-tight text-gray-900"
          >
            Ofte stillede spørgsmål
          </h2>
          <p className="mt-2 text-lg text-gray-600">
            Hvis du har andre spørgsmål, kan du altid{" "}
            <Link
              href="mailto:support@min-mave.dk"
              className="text-gray-900 underline"
            >
              skrive til os
            </Link>
            .
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-2"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="space-y-10">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="text-lg font-semibold leading-6 text-gray-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-gray-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
