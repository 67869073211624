import { Fragment, useEffect, useId, useRef, useState } from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useDebouncedCallback } from "use-debounce";

import { AppScreen } from ".//AppScreen";
import { CircleBackground } from ".//CircleBackground";
import { Container } from ".//Container";
import { PhoneFrame } from ".//PhoneFrame";

const MotionAppScreenHeader = motion(AppScreen.Header);
const MotionAppScreenBody = motion(AppScreen.Body);

const features = [
  {
    name: "70 videoer og podcasts, tilpasset til din uge.",
    description:
      "Hver uge i din graviditet får du nyt indhold på din side. Det kan være videoer med vores fagfolk, podcasts eller opskrifter og træningsprogrammer. Indholder passer til hvor du er lige nu.",
    screen: LessonScreen,
  },
  {
    name: "En online goodiebox",
    description:
      "Vi er gået sammen med nogle af de bedste brands for at kunne tilbyde dig særligt gode tilbud under din graviditet. Om det er en lydbog til gåturene, privat scanning, ventetøj eller noget helt andet; du finder det nok her.",
    screen: StocksScreen,
  },
];

const headerAnimation = {
  initial: { opacity: 0, transition: { duration: 0.3 } },
  animate: { opacity: 1, transition: { duration: 0.3, delay: 0.3 } },
  exit: { opacity: 0, transition: { duration: 0.3 } },
};

const maxZIndex = 2147483647;

const bodyVariantBackwards = {
  opacity: 0.4,
  scale: 0.8,
  zIndex: 0,
  filter: "blur(4px)",
  transition: { duration: 0.4 },
};

const bodyVariantForwards = (custom) => ({
  y: "100%",
  zIndex: maxZIndex - custom.changeCount,
  transition: { duration: 0.4 },
});

const bodyAnimation = {
  initial: "initial",
  animate: "animate",
  exit: "exit",
  variants: {
    initial: (custom) =>
      custom.isForwards ? bodyVariantForwards(custom) : bodyVariantBackwards,
    animate: (custom) => ({
      y: "0%",
      opacity: 1,
      scale: 1,
      zIndex: maxZIndex / 2 - custom.changeCount,
      filter: "blur(0px)",
      transition: { duration: 0.4 },
    }),
    exit: (custom) =>
      custom.isForwards ? bodyVariantBackwards : bodyVariantForwards(custom),
  },
};

function LessonScreen({ custom, animated = false }) {
  return (
    <AppScreen className="w-full">
      <MotionAppScreenHeader {...(animated ? headerAnimation : {})}>
        <AppScreen.Title>
          Uge 18: Derfor skal du blive ved med at træne igennem dit 2 trimester
        </AppScreen.Title>
        <AppScreen.Subtitle>
          I denne uge snakker vi om hvorfor det er vigtigt, at fortsætte sin
          træning i 2. trimester også tager Kate dig igennem en omgang
          opvarmning inden vi starter næste gravid workout i næste uge.
        </AppScreen.Subtitle>
      </MotionAppScreenHeader>
      <MotionAppScreenBody {...(animated ? { ...bodyAnimation, custom } : {})}>
        <div className="px-4 pt-6">
          <div className="text-base text-mm-purple font-bold mb-2">
            Lektioner
          </div>
          <img
            src="/images/placeholder-img.png"
            alt="En lektion fra Min Mave"
            className="mb-2"
          />
          <div className="text-sm font-medium text-mm-purple mb-1">
            Sjove og gode træningsøvelser du kan lave med dit andet barn
          </div>
          <div className="text-sm font-medium text-black">
            Det er vigtigt at du ikke stopper med at træne når du bliver gravid
            og det kan være særligt svært hvis du har et barn eller flere
            derhjemme. Kate guider dig igennem gode øvelser, som du kan lave
            hele din graviditet med dit andet barn.
          </div>
        </div>
      </MotionAppScreenBody>
    </AppScreen>
  );
}

function StocksScreen({ custom, animated = false }) {
  return (
    <AppScreen className="w-full">
      <MotionAppScreenHeader {...(animated ? headerAnimation : {})}>
        <AppScreen.Title>Goodiebox</AppScreen.Title>
      </MotionAppScreenHeader>
      <MotionAppScreenBody {...(animated ? { ...bodyAnimation, custom } : {})}>
        <div className="px-4 pt-6">
          <div className="rounded-lg bg-mm-light-rose p-4 mb-4">
            <div className="text-sm mb-2 text-mm-purple font-medium">
              Få -30% på din gravidgarderobe hos Mamalicious
            </div>
            <img
              src="http://images.ctfassets.net/sbgqvw7ytagj/2Pr93OrPmXeLWa1psLL7KQ/e38795bcd9386b93b88386b7f0f35229/Untitled-1_03.jpg"
              alt="Spar på graviditetstøjet hos Mamalicious"
            />
          </div>
          <div className="rounded-lg bg-mm-light-rose p-4">
            <div className="text-sm mb-2 text-mm-purple font-medium">
              Få -30% på din første box af gravidvitaminer fra Zentabox
            </div>
            <img
              src="http://images.ctfassets.net/sbgqvw7ytagj/36dVnKgtXqWcPHI6k9mmA4/e7fffb9ef25c6ec735d4bf2283297ec9/Zentabox_Banner_600x400_v1_knap2.jpg"
              alt="Spar på gravidvitaminer fra Zentabox"
            />
          </div>
        </div>
      </MotionAppScreenBody>
    </AppScreen>
  );
}

function usePrevious(value) {
  let ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FeaturesDesktop() {
  let [changeCount, setChangeCount] = useState(0);
  let [selectedIndex, setSelectedIndex] = useState(0);
  let prevIndex = usePrevious(selectedIndex);
  let isForwards = prevIndex === undefined ? true : selectedIndex > prevIndex;

  let onChange = useDebouncedCallback(
    (selectedIndex) => {
      setSelectedIndex(selectedIndex);
      setChangeCount((changeCount) => changeCount + 1);
    },
    100,
    { leading: true }
  );

  return (
    <Tab.Group
      as="div"
      className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24"
      selectedIndex={selectedIndex}
      onChange={onChange}
      vertical
    >
      <Tab.List className="relative z-10 order-last col-span-6 space-y-6">
        {features.map((feature, featureIndex) => (
          <div
            key={feature.name}
            className="relative rounded-2xl transition-colors hover:border-mm-purple"
          >
            {featureIndex === selectedIndex && (
              <motion.div
                layoutId="activeBackground"
                className="absolute inset-0 bg-mm-light-rose"
                initial={{ borderRadius: 16 }}
              />
            )}
            <div className="relative z-10 p-8">
              <h3 className="text-lg font-medium text-black">
                <Tab className="text-left [&:not(:focus-visible)]:focus:outline-none">
                  <span className="absolute inset-0 rounded-2xl" />
                  {feature.name}
                </Tab>
              </h3>
              <p className="mt-2 text-sm text-black">{feature.description}</p>
            </div>
          </div>
        ))}
      </Tab.List>
      <div className="relative col-span-6">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <CircleBackground color="#b297b4" className="animate-spin-slower" />
        </div>
        <PhoneFrame className="z-10 mx-auto w-full max-w-[366px]">
          <Tab.Panels as={Fragment}>
            <AnimatePresence
              initial={false}
              custom={{ isForwards, changeCount }}
            >
              {features.map((feature, featureIndex) =>
                selectedIndex === featureIndex ? (
                  <Tab.Panel
                    static
                    key={feature.name + changeCount}
                    className="col-start-1 row-start-1 flex focus:outline-offset-[32px] [&:not(:focus-visible)]:focus:outline-none"
                  >
                    <feature.screen
                      animated
                      custom={{ isForwards, changeCount }}
                    />
                  </Tab.Panel>
                ) : null
              )}
            </AnimatePresence>
          </Tab.Panels>
        </PhoneFrame>
      </div>
    </Tab.Group>
  );
}

function FeaturesMobile() {
  let [activeIndex, setActiveIndex] = useState(0);
  let slideContainerRef = useRef();
  let slideRefs = useRef([]);

  useEffect(() => {
    let observer = new window.IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            setActiveIndex(slideRefs.current.indexOf(entry.target));
            break;
          }
        }
      },
      {
        root: slideContainerRef.current,
        threshold: 0.6,
      }
    );

    for (let slide of slideRefs.current) {
      if (slide) {
        observer.observe(slide);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [slideContainerRef, slideRefs]);

  return (
    <>
      <div
        ref={slideContainerRef}
        className="-mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 [&::-webkit-scrollbar]:hidden"
      >
        {features.map((feature, featureIndex) => (
          <div
            key={featureIndex}
            ref={(ref) => (slideRefs.current[featureIndex] = ref)}
            className="w-full flex-none snap-center px-4 sm:px-6"
          >
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-100 px-5 py-6">
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <CircleBackground
                  color="#13B5C8"
                  className={featureIndex % 2 === 1 ? "rotate-180" : undefined}
                />
              </div>
              <PhoneFrame className="relative mx-auto w-full max-w-[366px]">
                <feature.screen />
              </PhoneFrame>
              <div className="absolute inset-x-0 bottom-0 bg-mm-light-rose p-6 backdrop-blur sm:p-10">
                <h3 className="text-sm font-medium text-black sm:text-lg">
                  {feature.name}
                </h3>
                <p className="mt-2 text-sm text-black">{feature.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-center gap-3">
        {features.map((_, featureIndex) => (
          <button
            type="button"
            key={featureIndex}
            className={clsx(
              "relative h-0.5 w-4 rounded-full",
              featureIndex === activeIndex ? "bg-gray-300" : "bg-gray-500"
            )}
            aria-label={`Go to slide ${featureIndex + 1}`}
            onClick={() => {
              slideRefs.current[featureIndex].scrollIntoView({
                block: "nearest",
                inline: "nearest",
              });
            }}
          >
            <span className="absolute -inset-x-1.5 -inset-y-3" />
          </button>
        ))}
      </div>
    </>
  );
}

export function PrimaryFeatures() {
  return (
    <section
      id="features"
      aria-label="Features for investing all your money"
      className="bg-gray-100 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
          <h2 className="text-3xl font-medium tracking-tight text-black">
            Alt hvad du skal bruge under <span className="italic">hele</span>{" "}
            graviditeten
          </h2>
          <p className="mt-2 text-lg text-black">
            Vores graviditetsforløb er lavet til at følge dig fra uge 6 og helt
            indtil fødslen. Indholdet frigives løbende som din graviditet
            udvikler sig, så du har altid det mest relevante indhold ved hånden
            og undgår at blive overvældet med information.
          </p>
        </div>
      </Container>
      <div className="mt-16 md:hidden">
        <FeaturesMobile />
      </div>
      <Container className="hidden md:mt-20 md:block">
        <FeaturesDesktop />
      </Container>
    </section>
  );
}
